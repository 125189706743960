import './Toast.scss';

import classNames from 'classnames';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  error?: boolean;
  info?: boolean;
  success?: boolean;
}

const Toast = ({ children, error, info, success }: Props) => {
  const className = 'c-Toast';

  const activeClassNames = classNames(className, {
    [`${className}--error`]: error,
    [`${className}--info`]: info,
    [`${className}--success`]: success,
  });

  let dataTestId = 'toast-message';
  if (error) {
    dataTestId = 'toast-error-message';
  }
  if (info) {
    dataTestId = 'toast-info-message';
  }
  if (success) {
    dataTestId = 'toast-success-message';
  }

  return (
    <div className={activeClassNames} data-testid={dataTestId}>
      {children}
    </div>
  );
};

export default Toast;
